<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
          <v-flex xs12 sm6 md8 text-left>
            <span class="mainHeader3">AFTER ACIDING :</span>
          </v-flex>
          <v-flex xs12 sm3 md2 text-left pl-1 pt-1>
        <v-btn dark color="#3F053C" block @click="$router.push('/AddAciding')"
          ><v-icon>mdi-plus</v-icon> New Batch</v-btn
        >
      </v-flex>
      <v-flex xs12 sm3 md2 text-left pl-1 pt-1>
        <v-btn dark color="#3F053C" block @click="$router.push('/AcidingReport')"
          >Report</v-btn
        >
      </v-flex>
          </v-layout>
    <v-layout wrap>
      <v-flex xs12 py-4>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
       
          <v-layout wrap pa-4 class="subhed3">
            <v-flex xs12 sm12 pr-2>
              <v-layout wrap>
                <v-flex xs12 sm9 md10 text-left class="subhed2 pb-2">Add Returned Item Details:</v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" text-left pr-2>
                  <span>Batch Code</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-autocomplete
                    :items="List"
                    v-model="batchCode"
                    @input="checktype(batchCode)"
                    placeholder="Select supplier"
                    item-text="batchNumber"
                    item-value="_id"
                    solo flat class="txtfield2"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <!--  -->
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2  text-left>
                  <span>Before Aciding(gm)</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    v-model="beforeAciding"
                     hide-details="auto"
                    type="number" disabled
                    color="#b234a9"
                    solo flat class="txtfield2"
                    placeholder="Before Aciding"
                    dense
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2  text-left> <span>Aciding Date</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                      solo flat class="txtfield2" dense
                        hide-details="" disabled
                        v-model="acidingDate"
                      ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 pr-2 class="subhed2"  text-left> <span>Return Date</span></v-flex>
                <v-flex xs12 pr-2 >
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      solo flat class="txtfield2" dense
                        hide-details=""
                        color="#b234a9"
                        append-icon="mdi-calendar-multiselect"
                        readonly label="Date"
                        v-model="returnDate"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="from = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="returnDate"
                      color="#571964"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-flex>
            
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <!--  -->
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2  text-left>
                  <span>Returned Thankam</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    v-model="afterAciding"
                     hide-details="auto"
                    type="number"
                    color="#b234a9"
                    solo flat class="txtfield2"
                    placeholder="After Aciding Thankam"
                    dense
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2  text-left>
                  <span>Boarding Rate(₹)</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    @input="cal1()"
                    type="number"
                    v-model="boardingRate"
                    solo flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Boarding Rate "
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2>
                  <span>Supplier</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-autocomplete
                    :items="SupplierList"
                    v-model="supplierCode"
                    placeholder="Select acidifier"
                    item-text="supplierName"
                    item-value="_id"
                    solo flat class="txtfield2"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 pb-2>
              <v-layout wrap>
                <v-flex xs12 class="subhed2" pr-2  text-left>
                  <span>Melt(%)</span></v-flex
                >
                <v-flex xs12 pr-2 >
                  <v-text-field
                    type="number"
                    v-model="meltPercentage"
                    solo flat class="txtfield2"
                    color="#b234a9"
                    placeholder="Melt percentage"
                    dense
                    :rules="[maxValueRule,minValueRule]"
                     hide-details="auto"
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 sm1 pr-2 align-self-end>
              <v-layout wrap>
                <v-flex xs6 pr-1 pb-0 pb-sm-2>
                  <v-btn @click="validate()" color="#3F053C" dark small
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!---------------------Table-------------------------->
          <v-layout px-4 py-4 wrap v-if="List2">
            <!-- <v-flex xs12 class="subhed2">Quotation {{ quotation }}</v-flex> -->
            <v-flex xs12 v-if="List2.length > 0">
              <v-card elevation="0" >
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left subhed"><b>S.No.</b></th>
                            <th class="text-left subhed">
                              <b>Batch Code</b>
                            </th>
                            <th class="text-left subhed"><b>Aciding Date</b></th>
                            <th class="text-left subhed">
                              <span class="subhed"><b>Before Aciding</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>Return Date</b></span
                              >
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>After Aciding</b></span
                              ><span class="subheeed">(Thankam)</span>
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>Thankam Rate</b></span
                              ><span class="subheeed">(₹)</span>
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>Melt</b></span
                              ><span class="subheeed">(%)</span>
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>Acidifier</b></span
                              >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in List2" :key="i" class="table">
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.batchNumber.batchNumber"
                                >{{ value.batchNumber.batchNumber }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.batchNumber.batchDate">
                                {{ formatDate(value.batchNumber.batchDate) }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.batchNumber.totalOldGoldNetWeight">{{
                                value.batchNumber.totalOldGoldNetWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.thankamReturnDate">
                                {{ formatDate(value.thankamReturnDate) }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.thankam">{{
                                value.thankam
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.thankamRate">{{
                                value.thankamRate
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.meltingLoss">{{
                                value.meltingLoss
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td v-if="value.acidifier">
                              <span v-if="value.acidifier.supplierName"
                                >{{ value.acidifier.supplierName }}</span
                              >
                              <span v-else>-</span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1"> No data found </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["title", "datareload", "customerName", "contact"],
  data() {
    return {
      quotation: "1",
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      menu2:false,
      msg: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      juwellaryId: localStorage.getItem("juwellaryId"),
      List:[],
      acidingDate:"",
      returnDate:"",
      batchCode:"",
      beforeAciding:"",
      afterAciding:"",
      boardingRate:"",
      supplierCode:"",
      SupplierList:[],
      List2:[],
      meltPercentage:"",
    };
  },
  computed: {
    taxType() {
      return localStorage.getItem("GSTstatus");
    },
    goldRate1() {
      return localStorage.getItem("goldRate");
    },
  },
  mounted() {
    this.getBatch();
    this.getSupplier();
    this.getAcidifiedList();
  },
  methods: {
    maxValueRule(value) {
      if (value > 100) {
        return "Maximum value is 100";
      }
      return true;
    },
    minValueRule: (value) => {
      if (value < 0) {
        return 'Minimum value is 0'
      } else {
        return true
      }
    },
    validate(){
      if (!this.batchCode) {
        this.msg = "Please enter batch code";
        this.showsnackbar = true;
      } else if (!this.beforeAciding) {
        this.msg = "Before aciding weight cannot be empty ";
        this.showsnackbar = true;
      } else if (!this.acidingDate) {
        this.msg = "Please enter aciding date";
        this.showsnackbar = true;
      } else if (!this.returnDate) {
        this.msg = "Please enter return date";
        this.showsnackbar = true;
      } 
      else if (!this.afterAciding) {
        this.msg = "After aciding weight cannot be empty ";
        this.showsnackbar = true;
      }else if (this.afterAciding > this.beforeAciding) {
        this.msg = "Thankam weight cannot be greater than aciding gold ";
        this.showsnackbar = true;
      }else if (!this.boardingRate) {
        this.msg = "Bording rate cannot be empty ";
        this.showsnackbar = true;
      }else if (!this.meltPercentage) {
        this.msg = "Melt percentage cannot be empty ";
        this.showsnackbar = true;
      } else if (!this.supplierCode) {
        this.msg = "Acidifier name cannot be empty ";
        this.showsnackbar = true;
      }  else {
        
        this.addBatch();
      }
    },
    getBatch() {
                axios({
                  url: "/oldgoldbatch/list",
                  method: "get",
                  headers: {
                    "x-auth-token": localStorage.getItem("token"),
                  },
                  params:{
                    page: this.page,
          count: 20,
                  },
                })
                  .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                      this.List = response.data.data;
                      this.Pagelength = Math.ceil(response.data.count / 20);
                    } else {
                      this.msg = response.data.msg;
                      this.showsnackbar = true;
                    }
                  })
                  .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                  });
              },
              checktype(item) {
      console.log("item=", item);
      var singleobj = this.List.find((x) => x._id == item);
      console.log("singleobj==", singleobj);
      if (singleobj) {
        this.beforeAciding = singleobj.totalOldGoldNetWeight;
        console.log("beforeAciding=", this.beforeAciding);
        var acidingDate1 = singleobj.batchDate;
        this.acidingDate = this.formatDate(acidingDate1)
        console.log("acidingDate=", this.acidingDate);
      } else {
        console.log("singleobj not found");
      }
    },
    addBatch() {
      axios({
        url: "/acidingdata/add",
        method: "POST",
        data: {
          batchNumber: this.batchCode,
          beforeacidingWeight: this.beforeAciding,
          acidingDate: this.acidingDate,
          thankamReturnDate: this.returnDate,
          thankam: this.afterAciding,
          thankamRate: this.boardingRate,
          acidifierName : this.supplierCode,
          meltingLoss:this.meltPercentage,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.batchCode = null;
            this.beforeAciding = null;
            this.acidingDate = null;
            this.returnDate = null;
            this.afterAciding = null;
            this.boardingRate = null;
            this.meltPercentage = null;
            this.getAcidifiedList();
            this.getBatch();
            // this.$refs.addcat.resetValidation();

          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSupplier() {
      axios({
        method: "get",
        url: "/supplier/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.SupplierList = response.data.data;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getAcidifiedList() {
      axios({
        method: "get",
        url: "/acidifiedgold/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.List2 = response.data.data;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
              formatDate(item) {
                var dt = new Date(item);
                var day = dt.getDate();
                var year = dt.getFullYear();
                // var hours = dt.getHours();
                // var minutes = dt.getMinutes();
                dt = dt.toString();
                // var ampm = hours >= 12 ? "pm" : "am";
                // hours = hours % 12;
                // hours = hours ? hours : 12;
                // minutes = minutes < 10 ? "0" + minutes : minutes;
                var strTime = day + " " + dt.slice(4, 7) + " " + year;
                //  +
                // " , " +
                // hours +
                // ":" +
                // minutes +
                // " " +
                // ampm;
          
                return strTime;
              },
  },
};
</script>
<style scoped>
.txtfield2{
  border-color: #757575;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px !important;
}
</style>